import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  highlight: {
    color: '#ff3203',
  },
}));

const Highlight = ({ children }) => {
  const classes = useStyles();

  return (
    <span className={classes.highlight}>{children}</span>
  );
};

Highlight.propTypes = {
  children: PropTypes.node.isRequired,
};

Highlight.defaultProps = {};

export default Highlight;
