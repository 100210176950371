import React from 'react';
import {
  makeStyles,
  Typography,
  Grid,
  Container,
} from '@material-ui/core';
import AppleIcon from '@material-ui/icons/Apple';
import classnames from 'classnames';

import Layout from '../components/Layout';
import Highlight from '../components/Highlight';
import Button from '../components/Button';
import SEO from '../components/seo';
import meshTextureImage from '../assets/images/homepage/mesh-texture.png';
import curveImage from '../assets/images/homepage/curve.png';
import curveShadowImage from '../assets/images/homepage/curve-shadow.png';
import curveStitchesImage from '../assets/images/homepage/stitches.png';
import PageContainerImage from '../assets/images/homepage/page-container.png';
import SoundBarsImage from '../assets/images/homepage/svg/bars.svg';
import DashesImage from '../assets/images/homepage/svg/dashes.svg';
import ImageOne from '../assets/images/homepage/image-1.png';
import ImageTwo from '../assets/images/homepage/image-2.png';
import ImageThree from '../assets/images/homepage/image-3.png';

const useStyles = makeStyles(theme => ({
  balls: {
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '4rem',
    height: '1em',

    '& > span': {
      display: 'block',
      lineHeight: '1em',
      position: 'absolute',
      top: '50%',
      left: '50%',
      opacity: 0,
      transform: 'translate(-50%, -50%) scale(0.65)',
      transition: '350ms transform cubic-bezier(0.42, 0, 0.34, 2.4), 200ms opacity ease-in-out',
    },

    '&.active-1 > span:nth-child(1), &.active-2 > span:nth-child(2), &.active-3 > span:nth-child(3), &.active-4 > span:nth-child(4), &.active-5 > span:nth-child(5), &.active-6 > span:nth-child(6), &.active-7 > span:nth-child(7), &.active-8 > span:nth-child(8)': {
      opacity: 1,
      transform: 'translate(-50%, -50%) scale(1)',
    },

    [theme.breakpoints.down('sm')]: {
      width: '3rem',
    },

    [theme.breakpoints.down('xs')]: {
      width: '2.5rem',
    },
  },
  masthead: {
    margin: 'auto',
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    background: `url('${meshTextureImage}') repeat`,
    backgroundSize: 1500,

    [theme.breakpoints.down('md')]: {
      height: '70vh',
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 4, 8),
    },

    '& h3': {
      maxWidth: 1000,

      [theme.breakpoints.up('md')]: {
        fontSize: '3rem',
      },

      [theme.breakpoints.down('md')]: {
        fontSize: '2.5rem',
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.75rem',
      },
    },
  },
  intro: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    background: `
      url(${curveImage}) no-repeat center 0,
      linear-gradient(to right, #ff5b00, #fe0302) center 150px no-repeat
    `,
    backgroundSize: '100%',
    padding: theme.spacing('12%', 0),
    margin: theme.spacing(-15, 0, 0),
    color: '#fff',

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(20, 0),
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(15, 0),
    },

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(12, 4),
      backgroundPosition: 'center 0, center 90px',
    },

    [theme.breakpoints.up('lg')]: {
      backgroundPosition: 'center 0, center 230px',
    },

    '& h4': {
      margin: '1rem 0 0',
    },

    '& p': {
      margin: 0,
      fontFamily: 'Shentox, Arial',
      fontSize: '1.5rem',

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.1rem',
      },
    },

    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
    },

    '&:before': {
      background: `url(${curveShadowImage}) no-repeat center 0`,
      backgroundSize: '100%',
    },

    '&:after': {
      top: -35,
      background: `url(${curveStitchesImage}) no-repeat center 0`,
      backgroundSize: '100%',
    },
  },
  pageContainer: {
    position: 'relative',
    padding: theme.spacing(10, 16),
    marginTop: '-12%',
    zIndex: 0,

    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4, 6),
    },

    '& img': {
      width: '100%',

      [theme.breakpoints.down('md')]: {
        width: '120%',
        margin: '0 -10%',
      },
    },

    '& p': {
      color: theme.palette.grey['500'],
      margin: 0,

      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(6),
      },
    },

    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      zIndex: 1,
      pointerEvents: 'none',
    },

    '&:before': {
      background: `url(${PageContainerImage}) no-repeat`,
      backgroundSize: '100%',
      zIndex: -2,
    },

    '&:after': {
      top: 300,
      background: `linear-gradient(to bottom, transparent 15%, ${theme.palette.background.default} 60%)`,
      zIndex: -1,
    },
    
    [theme.breakpoints.down('md')]: {
      '&:after': {
        top: 70,
        background: `linear-gradient(to bottom, transparent 60px, ${theme.palette.background.default} 200px)`,
      },
    },
  },
  blockOne: {
    [theme.breakpoints.up('md')]: {
      '& .MuiGrid-item:nth-child(1)': {
        order: 1,
      },
    },
    
  },
  blockThree: {
    [theme.breakpoints.up('md')]: {
      '& .MuiGrid-item:nth-child(1)': {
        order: 1,
      },
    },
  },
  footer: {
    padding: theme.spacing(3, 3, 6),
    textAlign: 'center',
    borderTop: `1px solid ${theme.palette.grey['200']}`,
    color: theme.palette.grey['400'],
    '& a': {
      padding: theme.spacing(3, 3, 6),
      textAlign: 'center',
      borderTop: `1px solid ${theme.palette.grey['200']}`,
      color: theme.palette.grey['400'],
    },
  },
}));

const IndexPage = () => {
  const classes = useStyles();

  const [activeBallIndex, setActiveBallIndex] = React.useState(1);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setActiveBallIndex((currentBallIndex) => {
        if (currentBallIndex >= 8) return 1;
        return currentBallIndex + 1;
      });
    }, 2000);
    
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Layout>
      <SEO />

      <div className={classes.masthead}>
        <Typography variant="h3">
          Your <Highlight>all-access</Highlight> pass to exclusive conversations with
          <span className={classnames(classes.balls, `active-${activeBallIndex}`)}>
            <span role="img" aria-label="basketball">🏀</span>
            <span role="img" aria-label="football">⚽️</span>
            <span role="img" aria-label="tennis">🎾</span>
            <span role="img" aria-label="american-football">🏈</span>
            <span role="img" aria-label="volleyball">🏐</span>
            <span role="img" aria-label="baseball">⚾️</span>
            <span role="img" aria-label="hockey">🏒</span>
            <span role="img" aria-label="golf">⛳️</span>
          </span>
          insiders and fans.
        </Typography>

        <Button href={process.env.GATSBY_APP_STORE_LINK}>
          <AppleIcon />
          Download on the App Store
        </Button>
      </div>

      <div className={classes.intro}>
        <SoundBarsImage />
        
        <Container fixed maxWidth="md">
          <Typography variant="h4">
            Changing the way we talk sports
          </Typography>

          <p>
            Locker Room is the only place for live audio conversations about the takes, rumors, news, and teams you care about.
          </p>
        </Container>
      </div>

      <Container fixed className={classes.pageContainer}>
        <Grid container alignItems="center" justify="space-between" className={classes.blockOne}>
          <Grid item md={5}>
            <img src={ImageOne} alt="" />
          </Grid>

          <Grid item md={5}>
            <DashesImage />

            <Typography variant="h4">
              React instantly to sports news as it happens
            </Typography>

            <p>
              Hear from other fans and insiders as the biggest stories drop. Jump on stage to discuss or quickly share your take in the Discussion tab.
            </p>
          </Grid>
        </Grid>
        
        <Grid container alignItems="center" justify="space-between" className={classes.blockTwo}>
          <Grid item md={5}>
            <img src={ImageTwo} alt="" />
          </Grid>

          <Grid item md={5}>
            <DashesImage />

            <Typography variant="h4">
              Gather all your friends in watch parties for the biggest games
            </Typography>

            <p>
              Cheer, talk trash, and make predictions in real time with fans, experts, and athletes.
            </p>
          </Grid>
        </Grid>

        <Grid container alignItems="center" justify="space-between" className={classes.blockThree}>
          <Grid item md={5}>
            <img src={ImageThree} alt="" />
          </Grid>
          
          <Grid item md={5}>
            <DashesImage />

            <Typography variant="h4">
              Rep your favorite teams and find your community
            </Typography>

            <p>
              Discover new conversations about the teams and topics that matter to you with the people who share your fandom.
            </p>
          </Grid>
        </Grid>
      </Container>

      <footer className={classes.footer}>
        <a href="http://www.bettylabs.io">Copyright 2020 Betty Labs</a>
      </footer>
    </Layout>
  );
};

export default IndexPage;
